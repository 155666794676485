<template>
  <div class="grade-settings-editor">
    <slot name="header"></slot>
    <div v-if="editMode">
      <ul class="edit-grades">
        <li :data-selected="grade[0]" :class="{'selected': isSelected(grade[0])}" v-for="grade in availableGrades" :key="grade.id">
          <a href="#" @click="toggle(grade[0])">
            Grade {{grade[1]}}
            <!-- times (x) svg -->
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.927 1L8.927 0L4.963 3.964L1 0L0 1L3.964 4.963L0 8.927L1 9.927L4.964 5.963L8.928 9.927L9.928 8.927L5.963 4.963L9.927 1Z" fill="black"/>
            </svg>
          </a>
        </li>
      </ul>
      <div class="edit-grades-buttons">
        <span>Select:</span>
        <a class="button-secondary-small" @click="select(true)" href="#">All</a>
        <a class="button-secondary-small" @click="select(false)" href="#">None</a>
      </div>
      <a class="button-primary" href="#" @click="update()">Save Settings</a>
    </div>
    <div v-else>
      <ul class="display-grades">
        <li v-for="grade in availableGrades.filter((item) =>{ return isSelected(item[0]) } )" :key="grade.id">
          <slot v-if="isSelected(grade[0])" name="checkmark-icon"></slot>
          Grade {{ grade[1] }}
        </li>
      </ul>
      <a class="button-secondary" @click="editMode=true" href="#">Edit Grade Settings</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "GradeSettingsEditor",
  components: {},
  data() {
    return {
      editMode: false,
      selectedGrades: this.initialSelectedGrades
    }
  },
  props: ['updateUrl', 'availableGrades', 'initialSelectedGrades'],
  methods: {
    toggle(grade) {
      if (this.isSelected(grade)) {
        this.selectedGrades.splice(this.selectedGrades.indexOf(grade), 1);
      }
      else {
        this.selectedGrades.push(grade)
      }
    },
    select(all) {
      this.selectedGrades = all ? this.availableGrades.map((item) => item[0]) : []
    },

    isSelected(grade) {
      return (this.selectedGrades.indexOf(grade) >= 0);
    },

    update() {
      let data = { selectedGrades: this.selectedGrades };

      fetch(this.updateUrl, {
        method: 'POST', // or 'PUT'
        headers: {
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(response => response.json())
          .then(data => {
            this.editMode = false;
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    }
  }
}
</script>
