<template>
  <div>
    <select class="search-bar--lesson-type" :value="modelValue" @change="this.$emit('update:modelValue', $event.target.value)">
      <option value="" v-bind:selected="!modelValue">{{title}}</option>
      <optgroup label="Lesson Types" v-if="grouped">
        <option v-for="lessonType in lessonTypes" :value="`lt-${lessonType.id}`" :key="`lt-${lessonType.id}`">{{ lessonType.title }}</option>
      </optgroup>
      <optgroup label="Activity Types" v-if="grouped">
        <option v-for="lessonType in activityTypes" :value="`at-${lessonType.id}`" :key="`at-${lessonType.id}`">{{ lessonType.title }}</option>
      </optgroup>

      <option v-if="!grouped" v-for="lessonType in lessonTypes" :value="`lt-${lessonType.id}`" :key="`at-${lessonType.id}`">{{ lessonType.title }}</option>
      <option v-if="!grouped" v-for="lessonType in activityTypes" :value="`at-${lessonType.id}`" :key="`at-${lessonType.id}`">{{ lessonType.title }}</option>
      </select>
  </div>
</template>
<script>
export default {
  emits: ['update:modelValue'],
  props: ['url', 'modelValue', "title", "grouped"],
  data() {
    return {
      lessonTypes: [],
      activityTypes: []
    }
  },
  created() {
    fetch(this.url.lesson, {
      headers: {
        'Accept': 'application/json'
      }
    }).then(response => response.json()).then((data) => {
      this.lessonTypes = data;
    })
    fetch(this.url.activity, {
      headers: {
        'Accept': 'application/json'
      }
    }).then(response => response.json()).then((data) => {
      this.activityTypes = data;
    })

  },
}
</script>