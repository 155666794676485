<template>
  <div class="search-bar--grades">
    <div @click="showGradeSelect" v-click-outside="hideGradeSelect">
      <input
        class="search-bar--grades-input"
        type="text"
        readonly
        :value="grades"
        placeholder="Search by Grade(s)"/>
      <div class="search-bar--grades-list border" v-if="dropdownVisible">
        <ul>
          <li v-for="item in availableGrades" :key="item.id">
            <label>
              <span>{{ item[1] }}</span>
              <input
                type="checkbox"
                v-model="selectedGrades"
                :value="item[0]"
              />
            </label>
          </li>
        </ul>
        <div class="search-bar--grade-buttons">
          <a class="button-secondary" @click="select(false)">None</a>
          <a class="button-secondary" @click="select(true)">All</a>
          <a class="button-primary" @click="triggerSearch()">Apply</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchBarGradeSelect",
  props: ["url", "modelValue"],
  emits: ["startSearch", "update:modelValue"],
  data() {
    return {
      selectedGrades: [],
      availableGrades: [],
      dropdownVisible: false,
    };
  },
  watch: {
    selectedGrades(a, b) {
      this.$emit("update:modelValue", this.selectedGrades);
    },
  },
  computed: {
    grades() {
      //TODO: format grades like 1-3,6,7-9 on server (maybe?)
      let grades = this.availableGrades.reduce((acc, item) => {
        if (this.selectedGrades.includes(item[0])) {
          acc.push(item[1]);
        }
        return acc;
      }, []);
      return `Grades: ${grades.join(", ")}`;
    },
  },
  methods: {
    triggerSearch() {
      this.dropdownVisible = false;
      this.$emit("startSearch");
    },
    select(all) {
      this.selectedGrades = all
        ? this.availableGrades.map((item) => item[0])
        : [];
    },
    showGradeSelect: function () {
      this.dropdownVisible = true;
    },
    hideGradeSelect: function () {
      this.dropdownVisible = false;
    },
  },
  created() {
    fetch(this.url, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.selectedGrades = data.grades;
        this.availableGrades = data.availableGrades;
      });
  },
};
</script>