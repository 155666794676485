<template>
  <div class="w-100">
    <div class="search-bar-collapse">
      <div class="container">
        <div class="search-bar--actionbar">
          <input
              class="search-bar--keywords"
              v-model="keyword"
              placeholder="Search Keyword(s)"/>

          <search-bar-grade-select
              class="d-none d-md-block"
              @start-search="search()"
              v-model="grades"
              :url="this.urlGrades"></search-bar-grade-select>

          <search-bar-select
              v-if="isTeacher"
              class="d-none d-lg-block"
              :title="typeSelectTitle"
              v-model="typeFilter"
              :url="{lesson: this.urlLessonTypes}"></search-bar-select>

          <search-bar-select
              v-if="isTeacherAndFamily"
              class="d-none d-lg-block"
              :title="typeSelectTitle"
              v-model="typeFilter"
              :grouped="true"
              :url="{lesson: this.urlLessonTypes, activity: this.urlActivityTypes}"></search-bar-select>

          <search-bar-select
              v-if="isFamily"
              class="d-none d-lg-block"
              :title="typeSelectTitle"
              v-model="typeFilter"
              :url="{activity: this.urlActivityTypes}"></search-bar-select>

          <a class="search-bar--submit" v-on:click="search()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
              <path
                  d="M21.72,19.84h-1l-.35-.34a8.17,8.17,0,1,0-.88.88l.34.35v1L26.13,28,28,26.13Zm-7.54,0a5.66,5.66,0,1,1,5.66-5.66A5.65,5.65,0,0,1,14.18,19.84Z"
                  style="fill: #fff"/>
            </svg>
          </a>

        </div>
      </div>
    </div>

    <div class="search-bar--results" v-if="show_results == true">
      <div class="search-bar--count"><strong>{{ results_count }}</strong> Result<span v-if="results_count != 1">s</span>
      </div>
      <a class="search-bar--clear" v-on:click="clearFilters()">Clear Filters</a>
    </div>

  </div>
</template>
<script>

import SearchBarSelect from './SearchBarSelect';
import SearchBarGradeSelect from "./SearchBarGradeSelect";

export default {
  components: {SearchBarSelect, SearchBarGradeSelect},
  data() {
    return {
      keyword: '',
      typeFilter: "",
      activityTypes: [],
      lessonTypes: [],
      show_results: false,
      results_count: 0,
    }
  },
  computed: {
    isTeacher() {
      return this.role === "0"
    },
    isTeacherAndFamily() {
      return this.role === "1"
    },
    isFamily() {
      return this.role === "2"
    },
    typeSelectTitle() {
      if (this.role === "0") {
        return "Search by Lesson Type"
      } else if (this.role === "1") {
        return "Search by Lesson Type or Activity Type"
      } else if (this.role === "2") {
        return "Search by Activity Type"
      }
      return ""
    }
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    this.keyword = params.get('k') != null ? params.get('k') : '';
    this.typeFilter = params.get('tf') != null ? params.get('tf') : '';
    this.grades = params.getAll('g').length > 0 ? params.getAll('g') : this.$store.state.grades;

    if (!this.redirect && (this.keyword !== '' || this.grades.length > 0 || this.typeFilter !== '')) {
      this.search();
    }
  },
  mounted() {
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Enter') {
        this.search();
      }
    });
  },
  props: {
    urlSearch: {type: String, required: true},
    urlLessonTypes: {type: String, required: true},
    urlActivityTypes: {type: String, required: true},
    urlGrades: {type: String, required: true},
    redirect: {type: Boolean, required: false, default: false},
    role: {type: String, required: true}
  },
  emits: ['searchFinished'],
  methods: {
    search() {
      const params = [['k', this.keyword], ['tf', this.typeFilter]];
      const grades = this.grades.map((item) => ['g', item]);
      let data = params.concat(grades)

      const qs = data
          .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
          .join('&');

      this.$store.commit('SET_QUERY', qs)

      if (this.redirect) {
        window.location.replace(`/library/?${qs}`);
        return;
      }

      fetch(`${this.urlSearch}?${qs}`, {
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => response.json()).then(data => {
        this.$store.commit('SET_RESULTS', data);
        this.show_results = true;
        this.results_count = data.activity_results.length + data.lesson_results.length + data.other_results.length;
      });
    },
    clearFilters() {
      window.location.replace(`/library/`);
      return;
    }
  },

}

</script>