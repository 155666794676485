import { createApp } from "vue";
import SearchBar from "./components/SearchBar";
import SearchbarSelect from "./components/SearchBarSelect";
import Card from './components/Card';
import store from "./store";
import { mapState } from 'vuex'
import GradeSettingsEditor from "./components/GradeSettingsEditor";
import VueCookies from "vue3-cookies";

// adds a custom vue directive called "v-click-outside"
const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      // check that click was outside the el and its children
      if (!(el == event.target || el.contains(event.target))) {
        // if so call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

if (document.getElementById('library-app')) {
    const LibraryApp = {
        components: { Card },
        computed: {
            ...mapState(['results', 'state', 'query']),
        },
        watch: {
            query(val, oldVal) {
                const url = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + val;
                window.history.pushState({ path: url }, '', url);
            }
        }
    }

    let app = createApp(LibraryApp)
        .use(store)
        .mount('#library-app')
}

const SearchBarApp = {}

let searchApp = createApp(SearchBarApp)
    .use(store)
    .component('search-bar', SearchBar)
    .component('search-bar-select', SearchbarSelect)
    .directive("click-outside", clickOutside) //loads "v-click-outside" directive into seachbar app
    .mount('#search-bar-app')


if (document.getElementById('profile-page-app')) {
    const ProfilePageApp = {
        components: { GradeSettingsEditor }
    }

    createApp(ProfilePageApp).use(VueCookies).use(store).mount('#profile-page-app');
}


const modeDropDown = document.getElementById('mode-dropdown')

if (modeDropDown) {
  if (modeDropDown.dataset['mode']) {
    modeDropDown.querySelector('option[value="' + modeDropDown.dataset['mode'] + '"]').selected = true
  }
  modeDropDown.addEventListener('input', () => {
    const option = modeDropDown.options[modeDropDown.selectedIndex]
    window.location.href = option.dataset['url']
  })
}
