import {createStore} from 'vuex'

export default createStore({
    state() {
        return {
            results: [],
            state: 'index',
            query: '',
            grades: []
        }
    },
    mutations: {
        SET_RESULTS: (state, results) => {
            state.results = results;
            state.state = 'search';
        },
        SET_QUERY: (state, query) => {
            state.query = query;
        },
        SET_SELECTED_GRADES: (state, grades) => {
            state.grades = grades;
        },
    },
    actions: {

    },
    modules: {}
})