<template>
  <div class="card">
    <slot name="icon-header"></slot>
    <!-- start: card left -->
    <div :class="cssClass + '--left'">
      <!-- title -->
      <h3>
        <span v-if="type === 'theme'">think</span>
        <strong>{{ item.title }}</strong>
      </h3>

      <!-- theme result - lesson count and grades-->
      <div v-if="type === 'theme'">
        <span v-if="item.lesson_count > 0 && isTeacher || isTeacherAndFamily">{{ item.lesson_count }} Lessons </span>
        <!-- @John: this needs to be bold, too -->
        <span v-if="item.activity_count > 0 && isFamily || isTeacherAndFamily">{{ item.activity_count }} Activities</span>

        <!-- display grades if they exist -->
        <span v-if="item.display_grades.length > 0">
          <span class="space-pipe">&#x7c;</span>
          Grades {{ item.display_grades }}
        </span>
      </div>

      <!-- lesson results - lesson type and grades  -->
      <div v-else-if="type != 'other' && type != 'theme'">
        <!-- lesson type -->
        <span>{{ item.lesson_type.title }}</span>
        <span>&nbsp;Type</span>

        <!-- display grades if they exist -->
        <span v-if="item.display_grades.length > 0">
          <span class="space-pipe">&#x7c;</span>
          Grades {{ item.display_grades }}
        </span>
      </div>

      <!-- other results - short description only -->
      <div v-else>
        <div v-html="item.short_description"></div>
      </div>
    </div>
    <!-- end: card left -->

    <!-- start: card right -->
    <div :class="cssClass + '--right'">
      <!-- theme and lesson results - short description  -->
      <div v-if="type != 'other'" v-html="item.short_description"></div>
      <!-- whitespace for missing description -->
      <div v-if="type != 'other' && item.short_description.length == 0">&nbsp;</div>

      <!-- view btn -->
      <a :href="item.href" class="button-primary-small">
        View
        <slot name="icon-footer"></slot>
      </a>
    </div>
    <!-- end: card right -->
  </div>
</template>

<script>
export default {
  name: "Card",
  props: ['item', 'type', "role"],
  computed: {
    isTeacher() {
      return this.role === "0"
    },
    isTeacherAndFamily() {
      return this.role === "1"
    },
    isFamily() {
      return this.role === "2"
    },

    cssClass() {
      if (this.type === 'theme') {
        return 'lib-results-theme'
      }
      else if (this.type === 'other') {
        return 'lib-results-other'
      }
      else if (this.type === 'lesson') {
        return 'lib-results-lesson'
      }
      else if (this.type === 'activity') {
        return 'lib-results-activity'
      }
      else {
        return 'lib-results-lesson'
      }
    }
  }
}
</script>

<style scoped>

</style>
